import React, { useContext, useEffect } from "react";

import { Page, Grid, Card, Table, colors, Icon, Button } from "tabler-react";
import C3Chart from "react-c3js";
import SiteWrapper from "../components/PageWrapper";
import { AppContext, GiftInfo, LocationData } from "../AppContext";
import client from "../api/ApiInstance";
import { Chart } from "./Chart";

function formatShortDate(d: Date) {
  const date = new Date(d);
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "avg",
    "sep",
    "okt",
    "nov",
    "dec"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]}`;
}

function formatDate(date: Date) {
  const months = [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar"
  ];
  return `${date.getDate()}. ${
    months[date.getMonth()]
  } ${date.getFullYear()}. ${date.getHours()}:${date.getMinutes()}`;
}

function getDate(syncDate: Date) {
  const formattedDate = formatDate(new Date(syncDate));
  return formattedDate;
}

function mapDataToGiftInfo(data: any) {
  let mappedData = data.reduce((map, obj) => {
    if (!map[obj.public_id]) {
      map[obj.public_id] = {
        name: obj.name,
        provider: obj.provider,
        expiredCnt: 0,
        issuedCnt: 0,
        redeemedCnt: 0
      };
    }
    if (obj.status == "expired") {
      map[obj.public_id].expiredCnt = obj.total;
    }
    if (obj.status == "issued") {
      map[obj.public_id].issuedCnt = obj.total;
    }
    if (obj.status == "redeemed") {
      map[obj.public_id].redeemedCnt = obj.total;
    }
    return map;
  }, {});

  return Object.values(mappedData) as GiftInfo[];
}

export function Partners() {
  const context = useContext(AppContext);
  let gotData = false;

  async function getRecycledItemsDataForPartner(interval: string) {
    try {
      const recycledItemsStats = await client.get("/recycled-items-stats", {
        params: { partnerId: "6", interval: interval }
      });
      context.setRecycledItemsStats(recycledItemsStats.stats);
    } catch (e) {
      console.log("fetch data failed", e);
    }
  }

  async function getDevicesDataForPartner() {
    try {
      const devices = await client.get("/devices", {
        params: { ts: Date.now(), partner_id: 6 }
      });
      context.setDevices(devices);
      const giftInfo = await client.get("/products/info", {
        params: { partnerId: 6 }
      });
      context.setGiftInfo(mapDataToGiftInfo(giftInfo.data));
      const locationData = await client.get("/locations/recycledCount", {
        params: { partnerId: 6 }
      });
      context.setLocationData(locationData.locationData);

      await new Promise(resolve =>
        setTimeout(() => {
          resolve(getDevicesDataForPartner());
        }, 60000)
      );
    } catch (e) {
      console.log("fetch data failed", e);
    }
    gotData = true;
  }

  useEffect(() => {
    if (!gotData) {
      getDevicesDataForPartner();
      getRecycledItemsDataForPartner(30);
    }
  }, []);

  return (
    <SiteWrapper logout={true}>
      <Page.Content>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Prese</Card.Title>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader colSpan={2}>Naziv</Table.ColHeader>
                    <Table.ColHeader>Lokacija</Table.ColHeader>
                    <Table.ColHeader>Broj presovanih limenki</Table.ColHeader>
                    <Table.ColHeader>Broj limenki u presi</Table.ColHeader>
                    <Table.ColHeader>Trenutna popunjenost prese %</Table.ColHeader>
                    <Table.ColHeader>Sinhronizovano</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {context.devices.map(device => (
                    <Table.Row key={device.id}>
                      <Table.Col className="w-1">
                        <Icon name="wifi" className={device.status} />
                      </Table.Col>
                      <Table.Col>
                        <i>{device.name}</i>
                      </Table.Col>
                      <Table.Col>{device.location.name}</Table.Col>
                      <Table.Col>{device.total_recycled_items}</Table.Col>
                      <Table.Col>{device.recycled_items}</Table.Col>
                      <Table.Col>
                        {device.capacity !== 0 &&
                          (device.recycled_items / device.capacity) * 100}
                      </Table.Col>
                      <Table.Col>{getDate(device.updated_at)}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Pokloni</Card.Title>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Ime</Table.ColHeader>
                    <Table.ColHeader>Sponzor</Table.ColHeader>
                    <Table.ColHeader>Ukupno rezervisano</Table.ColHeader>
                    <Table.ColHeader>Neiskorišćeno</Table.ColHeader>
                    <Table.ColHeader>Iskorisceno</Table.ColHeader>
                    <Table.ColHeader>Isteklo</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {context.giftInfo.map((gift, i) => (
                    <Table.Row key={i}>
                      <Table.Col>{gift.name}</Table.Col>
                      <Table.Col>{gift.provider}</Table.Col>
                      <Table.Col>{gift.expiredCnt + gift.redeemedCnt + gift.issuedCnt}</Table.Col>
                      <Table.Col>{gift.issuedCnt}</Table.Col>
                      <Table.Col>{gift.redeemedCnt}</Table.Col>
                      <Table.Col>{gift.expiredCnt}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12}>
            <Card>
              <Card.Header>
                <Card.Title>Broj recikliranih limenki po danima</Card.Title>
              </Card.Header>
              <Card.Body>
                <Button
                  link
                  onClick={async e => {
                    e.preventDefault();
                    await getRecycledItemsDataForPartner("30");
                  }}
                >
                  30 dana
                </Button>
                <Button
                  link
                  onClick={async e => {
                    e.preventDefault();
                    await getRecycledItemsDataForPartner("14");
                  }}
                >
                  14 dana
                </Button>
                <Button
                  link
                  onClick={async e => {
                    e.preventDefault();
                    await getRecycledItemsDataForPartner("7");
                  }}
                >
                  7 dana
                </Button>
                <C3Chart
                  style={{ height: "14rem" }}
                  data={{
                    x: "x",
                    columns: [
                      [
                        "x",
                        ...Array.from(
                          context.recycledItemsStats,
                          item => new Date(item.date)
                        )
                      ],
                      [
                        "broj recikliranih limenki",
                        ...Array.from(context.recycledItemsStats, item =>
                          item.total.toString(10)
                        )
                      ]
                    ]
                  }}
                  color={{
                    pattern: ["#ffbb78"]
                  }}
                  axis={{
                    x: {
                      type: "timeseries",
                      tick: {
                        format: formatShortDate
                      }
                    }
                  }}
                  legend={{
                    show: true //hide legend
                  }}
                  padding={{
                    bottom: 0,
                    top: 0
                  }}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={12}>
            <Card>
              <Card.Header>
                <Card.Title>Reciklaža po lokacijama</Card.Title>
              </Card.Header>
              <Card.Body>
                <Chart />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
