import React, { useEffect } from "react";
import c3 from "c3";
import client from "../api/ApiInstance";
import { LocationData } from "../AppContext";

export function Chart() {
    let gotData = false;
    const [data, setData] = React.useState([]);
    const [days, setDays] = React.useState(0);

    let locations: LocationData[];

    async function getChartData() {
        const locationData = await client.get("/locations/recycledCount", {
            params: { partnerId: 6 }
          });
    
        locations = locationData.locationData;

        var dataArray = [[],[]];

        locations.forEach(item => {
            var array = [];
            array[0] = item.name;
            array[1] = item.total;
            dataArray.push(array);
        })

        gotData = true;
        return dataArray;
    }

    async function getFilterData(numberOfDaysForFilter: number) {
        const locationData = await client.get("/locations/recycledCountByDays", {
            params: { partnerId: 6, numberOfDaysToInclude: numberOfDaysForFilter }
          });
    
        locations = locationData.locationData;

        var dataArray = [[],[]];

        locations.forEach(item => {
            var array = [];
            array[0] = item.name;
            array[1] = item.total;
            dataArray.push(array);
        })

        gotData = true;
        return dataArray;
    }

    React.useEffect(() => {
        const interval = setInterval(async () => {
        if (!gotData) {
            setData(
                await getChartData()
            );
        }
        }, );
        return () => clearInterval(interval);
  }, []);

    React.useEffect(() => {
        c3.generate({
          bindto: "#chart",
          data: {
            columns: data,
            type: "bar",
          },
        });
      }, [data]);


      async function handleChange(event) {
        if (event.target.value as number == 0) {
            setDays(0);
            setData (await getChartData());
        } else {
            setDays(event.target.values as number)
            setData(await getFilterData(event.target.value as number));
        }
      }

      return (
          <div>
            <select value={days} onChange={handleChange}>
                <option value="7">7 dana</option>
                <option value="14">14 dana</option>
                <option value="30">30 dana</option>
                <option value="0">Ukupno</option>
            </select>
            <div id="chart" />
        </div>
        );
}