import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Page, Grid, Card, Button, Table } from "tabler-react";
import SiteWrapper from "../components/PageWrapper";
import explain from "../assets/explain.png";
import yesWeCanPromo from "../assets/about.jpg";
import yesWeCanPromo1 from "../assets/about1.jpg";
import yesWeCanPromo2 from "../assets/about2.jpg";
import yesWeCanPromo3 from "../assets/about3.jpg";
import yesWeCanPromo4 from "../assets/about4.jpg";
import yesWeCanPromo5 from "../assets/about5.jpg";
import yesWeCanPromo6 from "../assets/about6.jpg";
import yesWeCanPromo7 from "../assets/about7.jpg";


function About() {
  return (
    <SiteWrapper>
      <Page.Content
        className="welcome"
        style={{
          width: "100%",
          display: "flex",
          margin: "0 0 0 0",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", flexWrap: "nowrap", paddingBottom: '100px' }}>
          <div style={{ width: "40%", paddingTop: "80px" }}>
            {/* <img src={yesWeCanPromo} width="400px" />
            <img src={yesWeCanPromo1} width="400px" />
            <img src={yesWeCanPromo2} width="400px" /> */}
            <img src={yesWeCanPromo3} width="300px" style={{marginBottom: '10px'}}/>
            <img src={yesWeCanPromo4} width="300px" style={{marginBottom: '10px'}}/>
            <img src={yesWeCanPromo5} width="300px" style={{marginBottom: '10px'}}/>
            <img src={yesWeCanPromo6} width="300px" style={{marginBottom: '10px'}}/>
            <img src={yesWeCanPromo7} width="300px" style={{marginBottom: '10px'}}/>

          </div>
          <div style={{ width: "60%", alignItems: "left", marginBottom: "40px" }}>
            <h2
              style={{
                color: "#7E7E7E",
                marginBottom: "2px",
                fontSize: 32,
                fontWeight: 300,
                textAlign: "left"
              }}
            >
              O projektu
            </h2>
            <h1 style={{ fontSize: 38 }}>
              Portal digitalnog sistema za reciklažu limenki
            </h1>
            <div
              style={{
                width: "15%",
                borderBottom: "10px solid #7EC364",
                alignSelf: "left"
              }}
            />
            <p className="intro">
            <p style={{paddingBottom: '100px'}} className="intro">Projekat „Pametni sistem za sakupljanje limenki u gradovima“ realizuje se u okviru
                  programa razvojnih partnerstava sa privatnim sektorom develoPPP.de.
                  Finansiraju ga zajednički Nemačko Savezno ministarstvo za ekonomsku saradnju i razvoj
                  (BMZ) i kompanije Ball Pakovanja Evropa, Mercator-S i Solagro Smart Recycling d.o.o.
                  Ovaj trogodišnji projekat koji sprovode u partnerstvu navedene kompanije u saradnji sa
                  Nemačkom organizacijom za međunarodnu saradnju (GIZ) i Recan Fondacijom ima za cilj
                  da doprinese uspostavljanju održivog sistema za sakupljanje limenki u gradovima u Srbiji.
                  Projektom je predviđeno pilotiranje novog digitalnog i inovativnog rešenja baziranog na
                  motivaciji i stimulisanju građana da uzmu aktivno učešće u sakupljanju aluminijumskih
                  limenki za reciklažu. Korišćenjem pametnih presa i Solagro web aplikacije, sistem
                  omogućava kontrolu i praćenje limenki ubačenih u pametnu presu, kao i zamenu ostvarenih
                  poena digitalnim vaučerima za kupovinu u supermarketima.
                  Pametni uređaji će u prvoj fazi, koja je predviđena za 2021.godinu, biti raspoređeni u 10
                  IDEA i IDEA Organic objekata širom Beograda.
                  U okviru projekta predviđeno je početkom naredne godine i formiranje radnog tela koje bi
                  doprinelo unapređenju komunikacije svih zainteresovanih strana i uspostavljanju
                  zajedničkog dijaloga za bolje planiranje i efikasno upravljanje infrastrukturom za sakupljanje
                  ovog ambalažnog otpada.</p>
            <h3>Budućnost reciklaže: Kampanja za pametnu reciklažu limenki pokrenuta u Beogradu</h3>
            Beograd, 22. januar – Sa ciljem da kroz aktivno učešće građana poveća stepen reciklaže i time doprinese
            očuvanju životne sredine, u okviru programa razvojnih partnerstava sa privatnim sektorom develoPPP.de
            pokrenuta je kampanja za reciklažu aluminijumskih limenki na pametnim presama. Kampanja se realizuje
            uz podršku Nemačke razvojne saradnjke koju sprovodi GIZ u okviru projekta „Pametni sistem za sakupljanje
            limenki u gradovima“, koju zajednički finansiraju nemačko Savezno ministarstvo za ekonomsku saradnju i
            razvoj (BMZ) i kompanije Ball Packaging Europe, Mercator-S i Solagro. Prva pametna presa lansirana je u
            IDEA Organic prodavnici u Ulici kralja Petra u Beogradu. Građani koji budu reciklirali limenke na pametnim
            uređajima imaće mogućnost da ostvaruju popuste u IDEA prodavnicama putem Solagro aplikacije, a
            najuspešnije reciklere očekuju brojne vredne nagrade.<br/><br/>

            Prva pametna presa na teritoriji Beograda puštena je u rad uz prisustvo gradske sekretarke za zaštitu životne
            sredine Ivane Vilotijević. „Želja nam je da Beograd postane prepoznatljiv kao prestonica koja očuvanje životne
            sredine svrstava među svoje prioritete i time doprinosi poboljšanju kvaliteta života svojih građana. Ovakvi
            projekti daju značajan doprinos ostvarenju naših namera i pozdravljamo nastojanja biznis sektora da uzme
            aktivnu ulogu u brizi o ekološkoj održivosti okruženja u kom posluje. Nadamo se da će naši sugrađani brzo steći
            naviku da koriste ovu presu i tako na jednostavan i efektan način doprineti da Beograd bude još čistiji“ naglasila
            je Ivana Vilotijević.<br/><br/>

            Violeta Kovačević, generalna direktorka kompanije Mercator-S, istakla je da je na trgovinskim lancima veliki
            deo odgovornosti kada je reč o podsticanju potrošača da recikliraju ambalažu. „Veoma smo ponosni na to što je
            prva pametna presa postavljena upravo u IDEA Organic objektu, jer koncept naše prodavnice promoviše
            vrednosti o svetu kojima ima budućnost, a to su zdraviji život i čistija sredina. Planirano je da kroz projekat
            postavimo pametne prese na ukupno 10 lokacija u Beogradu. Želimo da na ovaj način našim sugrađanima
            omogućimo da se što lakše uključe u proces reciklaže. Naša vizija održivog poslovanja temelji se i na velikoj
            brizi o životnoj sredini, jer ulažemo veliki trud i značajne resurse kako bismo obezbedili čistiju budućnost za nove
            generacije.“<br/><br/>
            Jelena Kiš, menadžer za održivost i regulatorne poslove za centralnu i istočnu Evropu kompanije „Ball
            Packaging Europe“, najvećeg svetskog proizvođač limenki, naglasila je da je održivost jedan od najvećih
            prioriteta kompanije. „Proces reciklaže treba da počne već prilikom proizvodnje ambalaže – sami proizvođači
            imaju odgovornost da je učine što jednostavnijom za reciklažu. Naša kompanija kontinuirano razvija svoju
            ekspertizu i tehnologije proizvodnje aluminijumskih limenki koje se lako recikliraju. Na ovaj način omogućavamo i
            drugim učesnicima u lancu reciklaže da ponovna upotreba resursa postane redovan deo njihove prakse.
            Ekološka održivost je cilj koji ne može da postigne sam pojedinac, pa čak ni samo jedna kompanija – to je cilj
            koji zahteva saradnju brojnih aktera na različitim nivoima i nastojimo da svojim primerom i poslovnom praksom
            podstaknemo i druge da se uključe.“<br/><br/>
            Nemanja Janić, jedan od osnivača i izvršni direktor kompanije „Solagro Smart Recycling“ koja je osmislila
            ovaj inovativni sistem za reciklažu limenki, detaljnije je predstavio njegov način rada. „Ovo je prvi pametni sistem
            za reciklažu ambalažnog otpada sa elementima igre i analizom podataka recikliranog materijala integrisan u
            loyalty program nekog supermarketa u regionu, a koji je u potpunosti razvijen od strane domaće kompanije –
            startapa Solagro. Time pokazujemo da mlade inovativne kompanije u Srbiji ne samo da idu u korak sa novim
            tehnologijama i trendovima u oblasti reciklaže, već ih i same postavljaju.“ On je izrazio veliku zahvalnost
            partnerima na projektu za nesebičnu podršku i mogućnost da se sistem prvo implementira upravo u Beogradu.<br/>v

            Daniela Funke, direktorka GIZ-a u Srbiji, ocenila je da ovaj projekat povezuje više aspekata razvoja kojima
            Nemačka razvojna saradnja pruža kontinuiranu podršku. „Veliko nam je zadovoljstvo da kroz partnerstvo sa
            privatnim sektorom zajednički kreiramo nova digitalna rešenja za zaštitu životne sredine, a podsticanjem
            inovacija mladih stručnjaka i startap kompanija doprinesemo održivom razvoju kako u Srbiji tako i u celom
            regionu Zapadnog Balkana“ naglasila je ona.<br/><br/>
            Aluminijumske limenke su 100% reciklabilne i kao takve predstavljaju najodrživiju vrstu ambalaže. Budući da se
            bez gubitaka na kvalitetu mogu beskonačno reciklirati, proces njihove reciklaže je zatvoreni krug i donosi veliku
            uštedu, dok istovremenu značajno smanjuje zagađenje životne sredine. Energija koja se sačuva recikliranjem
            četiri aluminijumske limenke za piće može pokretati mašinu za pranje veša jedan sat, dok jedna reciklirana
            limenka uštedi energiju koja je dovoljna za tročasovni rad televizora. Reciklaža limenki smanjuje potrošnju
            prirodnih resursa, kao i količinu otpada na deponijama.
            </p>
          </div>
        </div>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Vesti</Card.Title>
                <Button.List align="right" style={{ marginLeft: "auto" }}>
                  {/* <Button link>Označi sve kao pročitane</Button> */}
                </Button.List>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row deck cards>
                    <Table.ColHeader width={9}>Naslov</Table.ColHeader>
                    <Table.ColHeader width={4}>Datum</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://www.danas.rs/drustvo/pustena-u-rad-prva-pametna-presa-za-reciklazu-limenki-u-beogradu/">
                      Puštena u rad prva pametna presa za reciklažu limenki u Beogradu
                      </a>
                    </Table.Col>
                    <Table.Col>22. januar 2021.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://www.nirapress.com/buducnost-reciklaze-kampanja-za-pametnu-reciklazu-limenki-pokrenuta-u-beogradu/">
                      Budućnost reciklaže: Kampanja za pametnu reciklažu limenki pokrenuta u Beogradu
                      </a>
                    </Table.Col>
                    <Table.Col>22. januar 2021.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://urldefense.proofpoint.com/v2/url?u=http-3A__www.beograd.rs_lat_beoinfo_1782322-2Dradojicic-2Duoci-2Dsvetskog-2Ddana-2Dplanete-2Dzemlje-2Dpodizemo-2Dsvest-2Do-2Dvaznosti-2Dreciklaze_&d=DwMFBA&c=jF7FvYH6t0RX1HrEjVCgHQ&r=2GkUcqdRylZwhki1PRvWyCPplWv2fZW1OEBljpcrGaU&m=16SVavH30twvwAHVhhh5dtc-iQKlhkFZDMsxWgeCFoU&s=JX93S_nGs99AlwmkGcTrBQV30U4o7u-6vZMQ6R4dV34&e=">
                      Radojičić: Uoči Svetskog dana planete Zemlje podižemo svest o važnosti reciklaže
                      </a>
                    </Table.Col>
                    <Table.Col>21. april 2021.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__www.novosti.rs_beograd_vesti_989919_ubaci-2Dlimenku-2Dostvari-2Dpopust-2Dbeogradu-2Dpostavljena-2Dpametna-2Dpresa-2Dreciklirajte-2Dostvarite-2Dpravo-2Dolaksice-2Dpri-2Dkupovini&d=DwMFBA&c=jF7FvYH6t0RX1HrEjVCgHQ&r=2GkUcqdRylZwhki1PRvWyCPplWv2fZW1OEBljpcrGaU&m=16SVavH30twvwAHVhhh5dtc-iQKlhkFZDMsxWgeCFoU&s=lICcBZvndeRbj8hejqoDzIn1M96e-DToW7Ff_wvArvM&e=">
                      UBACI LIMENKU, OSTVARI POPUST: U Beogradu postavljena "pametna presa", reciklirajte i ostvarite pravo na olakšice pri kupovini
                      </a>
                    </Table.Col>
                    <Table.Col>22. april 2021.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://beogradski.rs/radojicic-uoci-svetskog-dana-planete-zemlje-podizemo-svest-o-vaznosti-reciklaze/">
                      Radojičić: Uoči Svetskog dana planete Zemlje podižemo svest o važnosti reciklaže
                      </a>
                    </Table.Col>
                    <Table.Col>22. april 2021.</Table.Col>
                  </Table.Row>
                  
                  <Table.Row>
                    <Table.Col colSpan={6} alignContent="right">
                      <Button.List>
                        <Button color="gray">1</Button>
                        <Button icon="chevron-right" />
                      </Button.List>
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(About);
