import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Page } from "tabler-react";
import SiteWrapper from "../components/PageWrapper";
import gizLogoUrl from '../assets/giz.png';
import limenkaLogoUrl from '../assets/solagro.png';
import recanLogoUrl from '../assets/recan.png';
import yesWeCanPromo from '../assets/barcode.jpg';
import mercator from '../assets/Mercator.png';
import banner from '../assets/banner.png';

function Welcome() {
  return (
    <SiteWrapper>
      <div style={{position: "relative", backgroundSize: "contain", backgroundImage: `url(${banner})`, borderBottom: "4px solid #1A924C", backgroundRepeat: 'no-repeat', width: '100%', margin: '0 auto', paddingBottom: '50.396%'}}>
        <div style={{position: "absolute", width: '100%', height: '100%', left: 0, top: 0, padding: "15vh 5vw"}}>
          <h1 style={{fontSize: '4vw', margin: '0', padding: '0', color: "#84BD43"}}>DA TI SE DOBRE <br/>STVARI DESE,</h1>
          <h1 style={{fontSize: '4vw', margin: '0', padding: '0', color: "white"}}>ODLAŽI LIMENKE<br/>U PAMETNE PRESE</h1>
          <h3 style={{fontSize: '1.5vw', marginTop: '12vw', padding: '0', color: "white"}}>Ubaci limenku, unesi kod u Solagro aplikaciju,<br/> sakupi poene i osvoji nagrade</h3>
        </div>
      </div>
      <div style={{ paddingTop: '40px', background: '#84BD43', display: "flex", flexWrap: "nowrap", alignItems: 'center', justifyContent: 'center', flexDirection: 'column', textAlign: 'center'}}>
        <div style={{ width: "100%", padding: '30px', color: 'white'}}>
          <h1 style={{fontSize: '5vw', margin: 0}}>NAGRAĐIVANJE</h1>
          <h1 style={{fontSize: '3vw', margin: 0}}>1 LIMENKA = 10 POENA</h1>

        </div>
        <div style={{display: "flex", flexWrap: "nowrap", width: '100%', height: '40%', marginBottom: '20px'}}>
          <div style={{width: '33.33%', color: 'white'}}>
            <h2>
            ZA OSVOJENIH<br/>
            50 POENA<br/>
            OSTVARUJETE<br/>
            10% POPUSTA<br/>
            NA ARTIKAL PO IZBORU<br/>
            </h2>
          </div>
          <div style={{width: '33.33%', color: 'white'}}>
            <h2>
            ZA OSVOJENIH<br/>
            100 POENA<br/>
            OSTVARUJETE<br/>
            20% POPUSTA<br/>
            NA ARTIKAL PO IZBORU<br/>
            </h2>
          </div>
          <div style={{width: '33.33%', color: 'white'}}>
            <h2>
            ZA OSVOJENIH<br/>
            200 POENA<br/>
            OSTVARUJETE<br/>
            25% POPUSTA<br/>
            NA ARTIKAL PO IZBORU<br/>
            </h2>
          </div>
        </div>
        <div style={{width: '100%', paddingBottom: '20px', color: 'white'}}>
          <p style={{fontSize: '1.5vw'}}>Popusti se ostvaruju na kasi preko digitalnih vaučera na <strong>Solagro</strong> aplikaciji https://solagro.app<br/>Korisnik koji do 30.04.2021. godine sakupi najviše poena, <strong>osvaja PS4 konzolu.</strong></p>
        </div>
      </div>
        <Page.Content className="welcome" style={{ width: "100%", display: "flex", margin: "0 0 0 0", justifyContent: 'center', alignItems: 'center'}}>
          
            <div style={{display: "flex", flexWrap: "nowrap"}}>
              <div style={{ width: "40%", padding: '30px'}}>
                <img src={yesWeCanPromo} style={{ borderRadius: '5%'}}/>
              </div>
              <div style={{ width: "60%", alignItems: "left" }}>
                <h2 style={{ color: "#7E7E7E", marginBottom: "2px", fontSize: 32, fontWeight: 300, textAlign: "left" }} >Dobrodošli na</h2>
                <h1 style={{ fontSize: 38 }}>Portal digitalnog sistema za reciklažu limenki</h1>
                <div style={{ width: "15%", borderBottom: "10px solid #7EC364", alignSelf: "left" }}></div>
                <p style={{paddingBottom: '100px'}} className="intro">Projekat „Pametni sistem za sakupljanje limenki u gradovima“ realizuje se u okviru
                  programa razvojnih partnerstava sa privatnim sektorom develoPPP.de.
                  Finansiraju ga zajednički Nemačko Savezno ministarstvo za ekonomsku saradnju i razvoj
                  (BMZ) i kompanije Ball Pakovanja Evropa, Mercator-S i Solagro Smart Recycling d.o.o.
                  Ovaj trogodišnji projekat koji sprovode u partnerstvu navedene kompanije u saradnji sa
                  Nemačkom organizacijom za međunarodnu saradnju (GIZ) i Recan Fondacijom ima za cilj
                  da doprinese uspostavljanju održivog sistema za sakupljanje limenki u gradovima u Srbiji.
                  Projektom je predviđeno pilotiranje novog digitalnog i inovativnog rešenja baziranog na
                  motivaciji i stimulisanju građana da uzmu aktivno učešće u sakupljanju aluminijumskih
                  limenki za reciklažu. Korišćenjem pametnih presa i Solagro web aplikacije, sistem
                  omogućava kontrolu i praćenje limenki ubačenih u pametnu presu, kao i zamenu ostvarenih
                  poena digitalnim vaučerima za kupovinu u supermarketima.
                  Pametni uređaji će u prvoj fazi, koja je predviđena za 2021.godinu, biti raspoređeni u 10
                  IDEA i IDEA Organic objekata širom Beograda.
                  U okviru projekta predviđeno je početkom naredne godine i formiranje radnog tela koje bi
                  doprinelo unapređenju komunikacije svih zainteresovanih strana i uspostavljanju
                  zajedničkog dijaloga za bolje planiranje i efikasno upravljanje infrastrukturom za sakupljanje
                  ovog ambalažnog otpada.</p>
                {/* <Link className="btn-large" style ={{marginRight: "20px", marginTop: "60px", width: "40%", backgroundColor: "#FDA144"}} to="/login">Pristup za javne partnere</Link>
                <Link className="btn-large" style ={{marginLeft: "20px", marginTop: "60px", width: "40%", backgroundColor: "#6497C3"}} to="/login">Pristup za privatne partnere</Link> */}
              </div>
            </div>
            <div style={{ borderBottom: "1px solid #7E7E7E", width: "100%"}}></div>
            <div className="partner-logos">
                <img src={gizLogoUrl} style={{width: '25%'}}/>
                <img src={limenkaLogoUrl} style={{width: '25%'}}/>
                <img src={recanLogoUrl} style={{width: '25%'}}/>
                <img src={mercator} style={{width: '25%'}}/>
            </div>
        </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(Welcome);
