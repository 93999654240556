import React, { useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import client from "../api/ApiInstance";

import {
  Page,
  Icon,
  Alert,
  Grid,
  Card,
  Table,
  Button,
  colors,
  Dropdown
} from "tabler-react";
import C3Chart from "react-c3js";

import SiteWrapper from "../components/PageWrapper";

function formatShortDate(date: Date) {
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "avg",
    "sep",
    "okt",
    "nov",
    "dec"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]}`;
}

function formatDate(date: Date) {
  const months = [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}. ${date.getHours()}:${date.getMinutes()}`;
}

function getDate(syncDate: Date) {
  const formattedDate = formatDate(new Date(syncDate));
  return formattedDate;
}

export function Municipalities() {
  const context = useContext(AppContext);
  let gotData = false;

  async function getData() {
    try {
      const devices = await client.get("/devices", {
        params: { ts: Date.now() }
      });
      context.setDevices(devices);
      await new Promise(resolve =>
        setTimeout(() => {
          resolve(getData());
        }, 5000)
      );
    } catch (e) {
      console.log("fetch data failed", e);
    }
    gotData = true;
  }

  useEffect(() => {
    if (!gotData) {
      getData();
    }
  }, []);

  return (
    <SiteWrapper logout={true}>
      <Page.Content>
        {context.devices
          .filter(dev => dev.low_capacity === 1 && dev.location.partner.name === 'Mercator')
          .map(device => (
            <Alert
              key={device.id}
              type="warning"
              icon="alert-triangle"
              isDismissible
            >
              Presa <b>{device.name}</b> na lokaciji {device.location.name} je
              više od 80% puna.
            </Alert>
          ))}
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Prese</Card.Title>
                {/* <Button.List
                  align="right"
                  style={{ marginLeft: "auto", display: "flex" }}
                >
                  <Dropdown
                    style={{ marginBottom: "auto" }}
                    type="button"
                    color="primary"
                    arrow
                    icon="globe"
                    triggerContent="Odaberite opštinu"
                    itemsObject={[
                      {
                        value: "Sve"
                      },
                      {
                        value: "Čukarica"
                      },
                      {
                        value: "Novi Beograd"
                      },
                      {
                        value: "Palilula"
                      },
                      {
                        value: "Savski venac"
                      },
                      {
                        value: "Rakovica"
                      },
                      {
                        value: "Stari grad"
                      },
                      {
                        value: "Voždovac"
                      },
                      {
                        value: "Vračar"
                      },
                      {
                        value: "Zemun"
                      },
                      {
                        value: "Zvezdara"
                      }
                    ]}
                  />
                  <Button
                    icon="plus"
                    color="success"
                    style={{ marginBottom: "auto" }}
                  >
                    Dodaj novu presu
                  </Button>
                </Button.List> */}
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader colSpan={2}>Naziv</Table.ColHeader>
                    <Table.ColHeader>Lokacija</Table.ColHeader>
                    <Table.ColHeader>Broj presovanih limenki</Table.ColHeader>
                    <Table.ColHeader>Broj limenki u presi</Table.ColHeader>
                    <Table.ColHeader>Popunjenost prese %</Table.ColHeader>
                    <Table.ColHeader>Sinhronizovano</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {context.devices.filter(dev => dev.location.partner.name === 'Mercator').map(device => (
                    <Table.Row key={device.id}>
                      <Table.Col className="w-1">
                        <Icon name="wifi" className={device.status} />
                      </Table.Col>
                      <Table.Col>
                        <i>{device.name}</i>
                      </Table.Col>
                      <Table.Col>{device.location.name}</Table.Col>
                      <Table.Col>{device.total_recycled_items}</Table.Col>
                      <Table.Col>{device.recycled_items}</Table.Col>
                      <Table.Col>{device.capacity !== 0 && ((device.recycled_items / device.capacity) * 100)}</Table.Col>
                      <Table.Col>{getDate(device.updated_at)}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col width={12}>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Grid.Col>
                    <Card>
                      <Card.Header>
                        <Card.Title>
                          Broj recikliranih limenki u poslednjih
                        </Card.Title>
                        <Button.List
                          align="right"
                          style={{ marginLeft: "auto", display: "flex" }}
                        >
                          <Dropdown
                            style={{ marginBottom: "auto" }}
                            type="button"
                            color="primary"
                            arrow
                            icon="globe"
                            triggerContent="30 dana"
                            itemsObject={[
                              {
                                value: "1 dana"
                              },
                              {
                                value: "3 dana"
                              },
                              {
                                value: "7 dana"
                              },
                              {
                                value: "10 dana"
                              },
                              {
                                value: "30 dana"
                              },
                              {
                                value: "godinu dana"
                              }
                            ]}
                          />
                        </Button.List>
                      </Card.Header>
                      <C3Chart
                        style={{ height: "13rem" }}
                        data={{
                          x: "x",
                          columns: [
                            [
                              "x",
                              "2019-04-10",
                              "2019-04-11",
                              "2019-04-12",
                              "2019-04-13",
                              "2019-04-14",
                              "2019-04-15",
                              "2019-04-16",
                              "2019-04-17",
                              "2019-04-18",
                              "2019-04-19",
                              "2019-04-20",
                              "2019-04-21",
                              "2019-04-22",
                              "2019-04-23",
                              "2019-04-24",
                              "2019-04-25",
                              "2019-04-26",
                              "2019-04-27",
                              "2019-04-28",
                              "2019-04-29",
                              "2019-04-30",
                              "2019-05-01",
                              "2019-05-02",
                              "2019-05-03",
                              "2019-05-04",
                              "2019-05-05",
                              "2019-05-06",
                              "2019-05-07",
                              "2019-05-08",
                              "2019-05-09"
                            ],
                            [
                              "cans",
                              311,
                              245,
                              721,
                              545,
                              231,
                              442,
                              423,
                              321,
                              113,
                              456,
                              201,
                              347,
                              321,
                              804,
                              404,
                              653,
                              179,
                              329,
                              437,
                              279,
                              631,
                              326,
                              543,
                              780,
                              106,
                              299,
                              754,
                              412,
                              256,
                              0
                            ]
                          ],
                          type: "area",
                          groups: [["cans"]],
                          colors: {
                            cans: colors["blue"]
                          },
                          names: {
                            cans: "Reciklirane limenke"
                          }
                        }}
                        axis={{
                          x: {
                            type: "timeseries",
                            tick: { format: formatShortDate }
                          }
                        }}
                        tooltip={{
                          format: {
                            title: formatDate
                          }
                        }}
                        padding={{
                          bottom: 0,
                          left: 30,
                          right: 20
                        }}
                      />
                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.ColHeader>Lokacija</Table.ColHeader>
                            <Table.ColHeader>Reciklirano</Table.ColHeader>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Col>Novi Beograd</Table.Col>
                            <Table.Col>6242 limenke</Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Stari Grad</Table.Col>
                            <Table.Col>2466 limenki</Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Palilula</Table.Col>
                            <Table.Col>2226 limenki</Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Čukarica</Table.Col>
                            <Table.Col>673 limenke</Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Voždovac</Table.Col>
                            <Table.Col>345 limenki</Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Vračar</Table.Col>
                            <Table.Col>218 limenki</Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card>
                  </Grid.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sm={4}>
            <Card>
              <Card.Header>
                <Card.Title>Pol korisnika</Card.Title>
              </Card.Header>
              <Card.Body>
                <C3Chart
                  style={{ height: "12rem" }}
                  data={{
                    columns: [
                      // each columns data
                      ["data1", 23],
                      ["data2", 57],
                      ["data3", 20]
                    ],
                    type: "pie", // default type of chart
                    colors: {
                      data1: colors["light-blue"],
                      data2: colors["green-light"],
                      data3: colors["gray"]
                    },
                    names: {
                      // name of each serie
                      data1: "Muški",
                      data2: "Ženski",
                      data3: "Nema informacije"
                    }
                  }}
                  legend={{
                    show: true //hide legend
                  }}
                  padding={{
                    bottom: 0,
                    top: 0
                  }}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card>
              <Card.Header>
                <Card.Title>Reciklaža po danima</Card.Title>
              </Card.Header>
              <Card.Body>
                <C3Chart
                  style={{ height: "12rem" }}
                  data={{
                    columns: [
                      // each columns data
                      ["data1", 71],
                      ["data2", 132],
                      ["data3", 93],
                      ["data4", 114],
                      ["data5", 125],
                      ["data6", 286],
                      ["data7", 207]
                    ],
                    type: "bar", // default type of chart
                    colors: {
                      data1: colors["light-blue"],
                      data2: colors["green-light"],
                      data3: colors["yellow"],
                      data4: colors["brown"],
                      data5: colors["purple"],
                      data6: colors["red"],
                      data7: colors["cyan"]
                    },
                    names: {
                      // name of each serie
                      data1: "Ponedeljak",
                      data2: "Utorak",
                      data3: "Sreda",
                      data4: "Četvrtak",
                      data5: "Petak",
                      data6: "Subota",
                      data7: "Nedelja"
                    }
                  }}
                  legend={{
                    show: true //hide legend
                  }}
                  padding={{
                    bottom: 0,
                    top: 0
                  }}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
          <Grid.Col sm={4}>
            <Card>
              <Card.Header>
                <Card.Title>Reciklaža po lokacijama</Card.Title>
              </Card.Header>
              <Card.Body>
                <C3Chart
                  style={{ height: "12rem" }}
                  data={{
                    columns: [
                      // each columns data
                      ["data1", 1211],
                      ["data2", 3127],
                      ["data3", 4232],
                      ["data4", 1541]
                    ],
                    type: "bar", // default type of chart
                    colors: {
                      data1: colors["green"],
                      data2: colors["blue"],
                      data3: colors["red"],
                      data4: colors["yellow"]
                    },
                    names: {
                      // name of each serie
                      data1: "Knez Mihajlova",
                      data2: "Tašmajdanski park",
                      data3: "ICT Hub",
                      data4: "25. Maj"
                    }
                  }}
                  legend={{
                    show: true //hide legend
                  }}
                  padding={{
                    bottom: 0,
                    top: 0
                  }}
                />
              </Card.Body>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Pristigle poruke</Card.Title>
                <Button.List align="right" style={{ marginLeft: "auto" }}>
                  <Button link>Označi sve kao pročitane</Button>
                </Button.List>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>Korisnik</Table.ColHeader>
                    <Table.ColHeader>Naslov poruke</Table.ColHeader>
                    <Table.ColHeader>Datum</Table.ColHeader>
                    <Table.ColHeader />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Col>nikolas@gmail.com</Table.Col>
                    <Table.Col>
                      <a href="#">
                        Kada će biti ispražnjeni podzemni kontejneri na Lionu?
                      </a>
                    </Table.Col>
                    <Table.Col>10. februar 2019.</Table.Col>
                    <Table.Col alignContent="right">
                      <Button icon="fe fe-message-circle" color="primary">
                        Nije pročitano
                      </Button>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>radojka123@hotmail.com</Table.Col>
                    <Table.Col>
                      <a href="#">Zasto nema presa na Novom Beogradu</a>
                    </Table.Col>
                    <Table.Col>10. februar 2019.</Table.Col>
                    <Table.Col alignContent="right">
                      <Button icon="check" color="green">
                        Pročitano
                      </Button>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>coacarina@gmail.com</Table.Col>
                    <Table.Col>
                      <a href="#">
                        Potrebno je više reciklažnih ostrva za amblažni otpad na
                        Adi Ciganliji
                      </a>
                    </Table.Col>
                    <Table.Col>9. februar 2019.</Table.Col>
                    <Table.Col alignContent="right">
                      <Button icon="check" color="green">
                        Pročitano
                      </Button>
                    </Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col colSpan={6} alignContent="right">
                      <Button.List>
                        <Button color="gray">1</Button>
                        <Button icon="chevron-right" />
                      </Button.List>
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
