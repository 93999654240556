import React, { useState, useContext, useEffect } from "react";
import { Form, Card, Grid, Page, StatsCard, Table, Button } from "tabler-react";
import imageUrlCan from "../assets/limenke-status.png";
import imageUrlKWh from "../assets/kw-status.png";
import imageUrlCO2 from "../assets/co2-status.png";
import giftIcon from "../assets/gift.png";
import userIcon from "../assets/user.png";
import recycleStatus from "../assets/recycle-status.png";
import SiteWrapper from "../components/PageWrapper";
import { AppContext } from "../AppContext";
import client from "../api/ApiInstance";
import ideaLogo from "../assets/idealogo.png";
import giftbox from "../assets/giftbox.png";

function formatDate(date: Date) {
  const months = [
    "januar",
    "februar",
    "mart",
    "april",
    "maj",
    "jun",
    "jul",
    "avgust",
    "septembar",
    "oktobar",
    "novembar",
    "decembar"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}.`;
}

function formatShortDate(date: Date) {
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "maj",
    "jun",
    "jul",
    "avg",
    "sep",
    "okt",
    "nov",
    "dec"
  ];
  return `${date.getDate()}. ${months[date.getMonth()]}`;
}

const styles = {
  verticalLayout: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center"
  },
  globalStats: {
    image: {
      width: "100%",
      maxWidth: 160,
      alignSelf: "center"
    },
    content: {
      padding: "1rem 0"
    },
    number: {
      fontSize: "2rem",
      display: "block",
      transform: "translateY(10%)"
    }
  }
};

function formatNumber(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function range(from, to) {
  return Array.from({ length: to - from }, (_, i) => i + from);
}

interface PaginationProps {
  page: number;
  numPages: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination = React.memo(
  ({ page, numPages, setPage }: PaginationProps) => {
    if (numPages < 2) {
      return null;
    }
    return (
      <Button.List>
        {page < 3 ? null : (
          <Button icon="chevrons-left" onClick={e => setPage(0)} />
        )}
        {page === 0 ? null : (
          <Button icon="chevron-left" onClick={e => setPage(page - 1)} />
        )}
        {range(Math.max(0, page - 2), Math.min(numPages, page + 3)).map(p => {
          return (
            <Button
              key={p}
              onClick={e => setPage(p)}
              color={p === page ? "gray-dark" : undefined}
            >
              {p + 1}
            </Button>
          );
        })}
        {page >= numPages - 1 ? null : (
          <Button icon="chevron-right" onClick={e => setPage(page + 1)} />
        )}
        {page >= numPages - 3 ? null : (
          <Button icon="chevrons-right" onClick={e => setPage(numPages - 1)} />
        )}
      </Button.List>
    );
  }
);

export function OpenData() {
  const context = useContext(AppContext);
  let gotData = false;
  const [page, setPage] = useState(0);

  async function getData() {
    try {
      const stats = await client.get("/stats", {
        params: { ts: Date.now() }
      });
      const gifts = await client.get("/products", {
        params: { limit: 100, ts: Date.now(), partner_id: 6 }
      });
      const rankings = await client.get("/rankings", {
        params: { limit: 15, partnerId: 6 }
      });
      context.setGifts(gifts);
      context.setStats(stats);
      context.setRankings(rankings);
      await new Promise(resolve =>
        setTimeout(() => {
          resolve(getData());
        }, 60000)
      );
    } catch (e) {
      console.log("fetch data failed", e);
    }
    gotData = true;
  }
  const itemsPerPage = 15;
  const numPages = Math.ceil(context.gifts.length / itemsPerPage);

  useEffect(() => {
    if (!gotData) {
      getData();
    }
  }, []);

  if (!Boolean(context.stats)) {
    return null;
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <h2 className="title">Javno dostupni podaci</h2>
        <Grid.Row cards={true} className="stats-card-container">
          <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={imageUrlCan} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {context.stats.recycled_items}
                    </strong>
                    <p>Ukupno recikliranih&nbsp;limenki</p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>

          <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={imageUrlKWh} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {formatNumber(
                        Math.round(context.stats.recycled_items * 0.2)
                      )}
                    </strong>
                    <p>Ušteda energije&nbsp;u&nbsp;KW</p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>

          <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={imageUrlCO2} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {formatNumber(
                        Math.round(context.stats.recycled_items * 9)
                      )}
                    </strong>
                    <p>
                      Smanjenje emisije CO<sub>2</sub>&nbsp;u&nbsp;Kg
                    </p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>

          <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={recycleStatus} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {context.stats.devices_can_crusher}
                    </strong>
                    <p>Ukupan broj&nbsp;presa</p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>

          {/* <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={giftIcon} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {context.stats.products_available}
                    </strong>
                    <p>Broj dostupnih nagrada</p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col> */}

          <Grid.Col width={12} lg={4}>
            <Card className="stats-card">
              <Grid.Row>
                <Grid.Col>
                  <div style={styles.verticalLayout}>
                    <img src={userIcon} style={styles.globalStats.image} />
                  </div>
                </Grid.Col>
                <Grid.Col width={8}>
                  <div
                    style={{
                      ...styles.verticalLayout,
                      ...styles.globalStats.content
                    }}
                  >
                    <strong style={styles.globalStats.number}>
                      {context.stats.users_registered}
                    </strong>
                    <p>Broj registrovanih korisnika</p>
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <h2 className="title">Nagrade</h2>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader style={{width: '33%'}} colSpan={2}>Nagrada</Table.ColHeader>
                    <Table.ColHeader style={{width: '33%'}} >Sponzor</Table.ColHeader>
                    <Table.ColHeader style={{width: '33%'}} >Dostupno</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {context.gifts
                    .slice(itemsPerPage * page, itemsPerPage * (page + 1))
                    .map((gift, i) => (
                      <Table.Row key={i}>
                        <Table.Col className="w-1" alignContent="center">
                          <img
                            height="40"
                            src={gift.image_url}
                            style={{ maxWidth: 120 }}
                          />
                        </Table.Col>
                        <Table.Col><img src={giftbox} style={{maxWidth: '60px', marginRight: '20px'}}/>{gift.name}</Table.Col>
                        <Table.Col ><img src={ideaLogo} style={{maxWidth: '60px'}}/></Table.Col>
                        <Table.Col>neograničeno</Table.Col>
                      </Table.Row>
                    ))}
                  <Table.Row>
                    <Table.Col colSpan={6} alignContent="right">
                      <Pagination
                        {...{
                          setPage,
                          page,
                          numPages
                        }}
                      />
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
        <h2 className="title">Rang lista</h2>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>#</Table.ColHeader>
                    <Table.ColHeader colSpan={2}>Korisnik</Table.ColHeader>
                    <Table.ColHeader>
                      Ukupan broj recikliranih proizvoda
                    </Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {context.rankings.map((ranking, i) => (
                    <Table.Row key={i}>
                      <Table.Col className="w-25">{i + 1}</Table.Col>
                      <Table.Col className="w-25">
                        {ranking.display_name}
                      </Table.Col>
                      <Table.Col className="w-25"></Table.Col>
                      <Table.Col className="w-25">{ranking.total}</Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
