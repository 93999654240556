import React from "react";
import {
  Form,
  Card,
  colors,
  Grid,
  Page,
  StatsCard,
  Table,
  Button
} from "tabler-react";
import SiteWrapper from "../components/PageWrapper";
import CrusherMap from "../components/CrusherMap";

export function MapScreen() {
  return (
    <SiteWrapper>
      <Page.Content>
        <Grid.Row cards={true} height={12}>
          <Grid.Col style={{minHeight: "600px"}}>
            <Card style={{minHeight: "600px", height: "600px"}}>
              <Card.Header>
                <Card.Title>
                Mreža dostupnih presa u Beogradu
                </Card.Title>
              </Card.Header>
            <CrusherMap style={{minHeight: "600px"}}/>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
