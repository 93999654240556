import React from "react";
import C3Chart from "react-c3js";
import {
  Form,
  Card,
  colors,
  Grid,
  Page,
  StatsCard,
  Table,
  Button
} from "tabler-react";
import SiteWrapper from "../components/PageWrapper";

export function News() {
  return (
    <SiteWrapper>
      <Page.Content>
        <Grid.Row cards={true}>
          <Grid.Col width={12}>
            <Card>
              <Card.Header>
                <Card.Title>Vesti</Card.Title>
                <Button.List align="right" style={{ marginLeft: "auto" }}>
                  {/* <Button link>Označi sve kao pročitane</Button> */}
                </Button.List>
              </Card.Header>
              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter"
              >
                <Table.Header>
                  <Table.Row deck cards>
                    <Table.ColHeader width={9}>Naslov</Table.ColHeader>
                    <Table.ColHeader width={4}>Datum</Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Col>
                      <a href="https://www.facebook.com/events/433468060742005/">
                      Na Dorćolu počela kampanja postani šampion digitalne reciklaže
                      </a>
                    </Table.Col>
                    <Table.Col>06. april 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                      Prvog dana Beogradskog Manifesta reciklirano preko 5000
                        limenki!
                      </a>
                    </Table.Col>
                    <Table.Col>10. maj 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                        Najavljen razvoj novog sistema za reciklazu
                      </a>
                    </Table.Col>
                    <Table.Col>9. februar 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                      Postavljeno 10 novih podzemnih kontejnera na Čukaričkoj Padini
                      </a>
                    </Table.Col>
                    <Table.Col>9. februar 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                      Postavljeno 16 novih podzemnih kontejnera na Novom Beogradu
                      </a>
                    </Table.Col>
                    <Table.Col>29. januar 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                      Donet novi zakon o reciklazi
                      </a>
                    </Table.Col>
                    <Table.Col>13. februar 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col>
                      <a href="#">
                      Zapocet razvoj novog sistema za reciklazu
                      </a>
                    </Table.Col>
                    <Table.Col>9. februar 2019.</Table.Col>
                  </Table.Row>
                  <Table.Row>
                    <Table.Col colSpan={6} alignContent="right">
                      <Button.List>
                        <Button color="gray">1</Button>
                        <Button icon="chevron-right" />
                      </Button.List>
                    </Table.Col>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
  );
}
