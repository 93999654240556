/// <reference path="index.d.ts" />
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Error404Page } from "tabler-react";
import "./App.css";
import {
  AppContext,
  Device,
  Fulfillment,
  Location,
  Partner,
  Product,
  Purchase,
  Stats,
  User,
  UserRanking,
  GiftInfo,
  LocationData,
  RecycledItemsStats
} from "./AppContext";
import { Admin } from "./pages/Admin";
import { OpenData } from "./pages/OpenData";
import { Partners } from "./pages/Partners";
import { News } from "./pages/News";
import { MapScreen } from "./pages/MapScreen";
import Welcome from "./pages/Welcome";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import { Municipalities } from "./pages/Municipalities";

export default function App() {
  const [stats, setStats] = useState<Stats>(null);
  const [user, setUser] = useState<User>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [gifts, setGifts] = useState<Product[]>([]);
  const [purchased, setPurchased] = useState<Purchase[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [giftInfo, setGiftInfo] = useState<GiftInfo[]>([]);
  const [locationData, setLocationData] = useState<LocationData[]>([]);
  const [fulfillments, setFulfillments] = useState<Fulfillment[]>([]);
  const [rankings, setRankings] = useState<UserRanking[]>([]);
  const [recycledItemsStats, setRecycledItemsStats] = useState<
    RecycledItemsStats[]
  >([]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        locations,
        setLocations,
        partners,
        setPartners,
        purchased,
        setPurchased,
        gifts,
        setGifts,
        stats,
        setStats,
        devices,
        setDevices,
        giftInfo,
        setGiftInfo,
        locationData,
        setLocationData,
        fulfillments,
        setFulfillments,
        rankings,
        setRankings,
        recycledItemsStats,
        setRecycledItemsStats
      }}
    >
      <React.StrictMode>
        <Router>
          <Switch>
            <Route exact path="/" component={Welcome} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/partners" component={Partners} />
            <Route exact path="/public" component={OpenData} />
            <Route exact path="/municipalities" component={Municipalities} />
            <Route exact path="/map" component={MapScreen} />
            <Route exact path="/news" component={News} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/fulfillments" component={Admin} />
            <Route component={Error404Page} />
          </Switch>
        </Router>
      </React.StrictMode>
    </AppContext.Provider>
  );
}
