import React, { Component } from "react";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import pinIcon from '../assets/pin.svg';
import greenPin from '../assets/greenpin.svg';

export const pointerIcon = new L.Icon({
  iconUrl: pinIcon,
  iconRetinaUrl: pinIcon,
  iconAnchor: [12, 32],
  iconSize: [23.5, 32],
  popupAnchor: [0, -34],
});

export const greenIcon = new L.Icon({
  iconUrl: greenPin,
  iconRetinaUrl: greenPin,
  iconAnchor: [12, 32],
  iconSize: [23.5, 32],
  popupAnchor: [0, -34],
});

export default class CustomIcons extends Component {
  state = {
    lat: 44.8023302,
    lng: 20.4501872,
    zoom: 12.75
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    const pins = [
        ['IDEA Organic, Kralja Petra 74', pointerIcon,  3448, 44.8226701, 20.458824111203207],
        ['IDEA Super, Bežanija', pointerIcon, 2440, 44.817791873823666, 20.383478345600416],
        ['IDEA Super, Nehruova 56b', pointerIcon, 1079, 44.80483953417701, 20.3781482879274],
        ['IDEA Super Robna kuća Beograd (Zemun)', pointerIcon, 942, 44.84343845659861, 20.41221541051868],
        ['IDEA London', pointerIcon, 1426, 44.80867350202939, 20.463196226547673],
        ['IDEA Super Zvezdara', pointerIcon, 1, 44.783551811957125, 20.52102930011889],
        ['IDEA Super Immo centar Novi Beograd', pointerIcon, 1, 44.80840895024041, 20.386226822840268],
        ['IDEA Super TC Ušće', pointerIcon, 1, 44.830630965722484, 20.40991322049676],
        ['IDEA Super Zemun Bačka', pointerIcon, 1, 44.84683084836879, 20.380751628402734],
        ['IDEA Super Južni Bulevar', pointerIcon, 1, 44.79396963924977, 20.484911891749714]
    ]
    return (
      <Map center={position} zoom={this.state.zoom} style={{ minHeight: 600, height: '100%' }}>
        <TileLayer
          attribution='Maps &amp;copy <a href="https://www.thunderforest.com/">Thunderforest</a>, Data <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'
          url="https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}.png?apikey=711d96cdbc8b43b8b9193bf0816ba48f"
        />
        {
            pins.map(([title, pin, cans, ...position], index) => (
                <Marker key={index} position={position} icon={pin}>
                <Popup>
                  <p>Naziv: { title }<br/>
                  Trenutni broj limenki: { cans } <br/>
                  Popunjeno: { cans / 20 }%</p></Popup>
                </Marker>
            ))
        }
        {/* <Marker position={position} icon={pointerIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
        <Marker position={position2} icon={suitcasePoint}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker> */}
      </Map>
    );
  }
}
