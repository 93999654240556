import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Card, Grid, Page, StatsCard, Table, Button } from "tabler-react";
import SiteWrapper from "../components/PageWrapper";
import explain from "../assets/explain.png";
import yesWeCanContactPromo from "../assets/yeswecancan.jpg";

function Contact() {
  return (
    <SiteWrapper>
      <Page.Content
        className="welcome"
        style={{
          width: "100%",
          display: "flex",
          margin: "0 0 0 0",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div style={{ display: "flex", flexWrap: "nowrap" }}>
          <div style={{ width: "40%", paddingTop: "80px" }}>
            <img src={yesWeCanContactPromo} width="400px" />
          </div>
          <div style={{ width: "60%", alignItems: "left", marginBottom: "40px" }}>
            <h2
              style={{
                color: "#7E7E7E",
                marginBottom: "2px",
                fontSize: 32,
                fontWeight: 300,
                textAlign: "left"
              }}
            >
              Kontakt podaci
            </h2>
            <h1 style={{ fontSize: 38 }}>
              Portal digitalnog sistema za reciklažu limenki
            </h1>
            <div
              style={{
                width: "15%",
                borderBottom: "10px solid #7EC364",
                alignSelf: "left"
              }}
            />
            <p className="intro">
            Zoran Jakovljev<br />
            Project Manager<br />
            T: +381 63 538 732<br />
            E: zoran.jakovljev@giz.de<br />
            W: www.giz.de<br />
            </p>
          </div>
          
        </div>
    
        {/* <div className="partner-logos">
          <img
            src={explain}
            style={{ height: "560px", minWidth: "840px", alignSelf: "center" }}
          />
        </div> */}
        <h2 className="title">Kontakt</h2>
          <div>
            <Grid.Row>
              <Grid.Col width={12}>
                <Card>
                  <Form method="post" action="/message">
                    <div style={{ padding: "1rem" }}>
                      <Grid.Row>
                        <Grid.Col lg={6} md={12}>
                          <Form.Group label="Naslov poruke">
                            <Form.Input name="text" type="text" />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={6} md={12}>
                          <Form.Group label="Vaš e-mail">
                            <Form.Input name="text" type="text" />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Col width={12}>
                          <Form.Group width={12} label="Sadržaj poruke">
                            <Form.Textarea name="message" />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                      <Grid.Row>
                        <div style={{ marginLeft: 12 }}>
                          <Button color="primary" type="submit">
                            Pošalji
                          </Button>
                        </div>
                      </Grid.Row>
                    </div>
                  </Form>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </div>
      </Page.Content>
    </SiteWrapper>
  );
}

export default withRouter(Contact);
