import React from "react";
import { NavLink, withRouter } from "react-router-dom";

import { Site, RouterContextProvider, Button } from "tabler-react";

import limenke from "../assets/limenke-status.png";
import boce from "../assets/boce-status.png";
import HeaderLogoImage from "../assets/logo.png";
import hand from "../assets/hand.png";

const navBarItems = [
  {
    value: <React.Fragment><img src={hand} height="70px" style={{ marginBottom: 0 }} /><h1 style={{ color: "white", marginBottom: 0, fontWeight: 300 }}>Digitalna reciklaža</h1></React.Fragment>,
    to: "/",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
  {
    value: "Naslovna",
    to: "/",
    icon: "home",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
  {
    value: "Javno dostupni podaci",
    to: "/public",
    icon: "globe",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
  {
    value: "Mapa",
    to: "/map",
    icon: "map",
    LinkComponent: withRouter(NavLink),
    useExact: true
  },
  // {
  //   value: "Organizacija podataka",
  //   to: "/login",
  //   // icon: "file-text",
  //   LinkComponent: withRouter(NavLink),
  //   useExact: true,
  // },
  // {
  //   value: "Vesti",
  //   to: "/news",
  //   // icon: "check-square",
  //   LinkComponent: withRouter(NavLink)
  // },
  {
    value: "O Projektu",
    to: "/about",
    icon: "info",
    LinkComponent: withRouter(NavLink)
  },
  {
    icon: "phone",
    value: "Kontakt",
    to: "/contact",
  }
];

export function SiteWrapper(props) {
  return (
    <Site.Wrapper
      style={{ justifyContent: 'center'}}
      headerProps={{
        href: "/",
        alt: "Yes, we CAN",
        imageURL: hand,
        navItems: !props.logout ? (
          undefined
        ) : (
            <Button RootComponent="a" href="/">
              Odjavite se
            </Button>
          )
      }}
      navProps={{ itemsObjects: navBarItems }}
      routerContextComponentType={withRouter(RouterContextProvider)}
      footerProps={{
        copyright: (
          <React.Fragment style={{ justifyContent: 'center', backgroundColor: "white" }}>
            © 2021{" "}
            <a href="http://solagroteam.com">Solagro Smart Recycling d.o.o.</a>{" "}
            Sva prava zadržana.
          </React.Fragment>
        )
      }}
    >
      {props.children}
    </Site.Wrapper>
  );
}

export default SiteWrapper;
